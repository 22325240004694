import React from 'react';
import InputField from 'components/InputField';
import Button from 'components/Button';

import {
    CREATE_POLICY_REPORT,
} from 'api';

import styles from './styles.module.scss';

class ChartReportPolicy extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            email: this.props.email,
            policyId: this.props.policyId.policyId,
            buttonDisabled: true,
            validationEmail: false,
        };
    }

    onChangeEmail(email) {
        let errorEmail = true;
        let isDisabled = true;

        if (email && new RegExp('^([a-zA-Z0-9_-]+\\.)*[a-zA-Z0-9_-]+@[a-z0-9_-]+(\\.[a-z0-9_-]+)*\\.[a-z]{2,6}$').test(email)) {
            isDisabled = false;
            errorEmail = false;
        }

        this.setState({
            email,
            buttonDisabled: isDisabled,
            validationEmail: errorEmail,
        });
    }

    sendReportPolicy() {
        this.setState({
            buttonDisabled: true,
            buttonLoading: true
        });

        CREATE_POLICY_REPORT(this.state.policyId, this.state.email).then((data) => {
            this.setState({
                ...this.state,
                reportFlag: data,
                buttonDisabled: false,
                buttonLoading: false
            });
        });
    }

    render() {
        return (
            <div className={styles.blockReportGenerator}>
                <h4>Сформировать отчет по полису</h4>
                <div className={styles.flexBlockReportGenerator}>
                    <InputField
                        size="lg"
                        type="text"
                        placeholder="E-mail"
                        value={this.state.email || ''}
                        error={this.state.validationEmail}
                        onChange={(e) => this.onChangeEmail(e.target.value)}
                    />
                    <Button
                        size="lg"
                        variant="primary"
                        className={styles.button}
                        disabled={this.state.validationEmail}
                        loading={this.state.buttonLoading}
                        onClick={() => this.sendReportPolicy()}>
                        {'Отправить'}
                    </Button>
                </div>
            </div>
        );
    }
}

export default ChartReportPolicy;
