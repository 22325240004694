import React from 'react';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import AppLayout from 'layouts/AppLayout';
import Wrapper from 'components/Wrapper';
import MainTopWrapper from 'components/MainTopWrapper';
import Container from 'components/Container';
import Heading from 'components/Heading';
import Badge from 'components/Badge';
import TitledContent from 'components/TitledContent';
import BaseList, { BaseListItem, BaseListText } from 'components/BaseList';
import ToggleTabs, { ToggleTabsItem, ToggleTabsWrapper } from 'components/ToggleTabs';
import TextContent from 'components/TextContent';
import ToggleWrapper from 'components/ToggleWrapper';
import FadeTransition from 'components/FadeTransition';
import Chart from 'components/Chart';
import ChartReportPolicy from 'components/ChartReportPolicy';
import Button from 'components/Button';
import BaseTable from 'components/BaseTable';

import RedemptionSumsTable from 'components/_modules/policy/RedemptionSumsTable';
import BasicAssetTable from 'components/_modules/policy/BasicAssetTable';
import EqualColumnsTable from 'components/_modules/policy/EqualColumnsTable';
import PaymentScheduleTable from 'components/_modules/policy/PaymentScheduleTable';
import InvestmentTable from 'components/_modules/policy/InvestmentTable';
import AdditionalProfitTable from 'components/_modules/policy/AdditionalProfitTable';
import TaxDeductionWidget from 'components/_modules/policy/TaxDeductionWidget';

import {
    GET_DATA_CHARTS,
} from 'api';

import ClinicList, { ClinicListItem, ClinicItem } from '../../components/ClinicList';

import styles from './styles.module.scss';

class PolicyPage extends React.Component {
    constructor(props) {
        super(props);

        this.store = props.store;

        this.state = {
            activeTab: 0,
            loading: true,
            graphAdditionalIncomeData: null,
            clampList: [],
            transactionID: 0,
            link: null,
        };

        this.fetchGraphAdditionalIncomeData = this.fetchGraphAdditionalIncomeData.bind(this);
    }

    componentDidMount() {
        document.title = this.store.policy.policy.productName || '';

        const { policyId } = this.props.match.params;

        this.store.policy.getPolicy(policyId).then(() => {
            document.title = this.store.policy.policy.productName || '';
            this.setState({
                ...this.state,
                loading: false,
            });
        });
    }

    componentWillUnmount() {
        this.store.policy.clearPolicy();
    }

    headingBadgeData() {
        const { policy } = this.store.policy;

        if (policy.status === 'PolicyStatus_Archival' || policy.status === 'PolicyStatus_Terminated' || policy.status === 'PolicyStatus_Completed' || policy.status === 'PolicyStatus_Annulated') {
            return {
                text: 'В архиве',
                color: '#828a9f',
            };
        }

        return {
            text: 'Активный',
            color: '#95c11f',
        };
    }

    fetchGraphAdditionalIncomeData(index) {
        const { policyId } = this.props.match.params;

        GET_DATA_CHARTS(index, policyId).then((data) => {
            this.setState({
                ...this.state,
                graphAdditionalIncomeData: data,
            });
        });
    }

    render() {
        const policyFullDate = `${moment(this.store.policy.policy.contractBeginDate).format('DD.MM.YYYY')} —— ${moment(this.store.policy.policy.contractEndDate).format('DD.MM.YYYY')}`;

        // Стратегии с блоком Отправки полиса в виде PDF
        // const strategyWithReportPolicy = ['CBKIGINF', 'SXDP', 'SOLHC', 'XLP', 'SGMDROBT', 'Медицина будущего 2.0', 'Энергогигант', 'КУРС НА РОСТ', 'Достигая максимум', 'Формула роста', 'Сельское хозяйство', 'Развивающиеся рынки', 'Энергетика будущего', 'Золото', 'Нефть', 'Телеком Инновации', 'ШП', 'Лидеры рынка', 'Пока все дома', 'SOLHC12'];
        const strategyWithReportPolicy = ['Голубые фишки', 'Бивалютный депозит', 'Лукойл. Фиксированный купон', 'Российские лидеры', 'Разумный выбор', 'Золото и точка'];
        const DetailsContentWrapper = ({
            title,
            padding = '55px 0 0',
            backgroundColor,
            isToggle,
            children,
        }) => (
            <div style={{ padding, backgroundColor }}>
                <Container fluid>
                    <div className={styles.SideWrapper}>
                        <div className={styles.SideWrapperInner}>
                            {isToggle
                                ? (
                                    <ToggleWrapper
                                        active={true}
                                        toggle={
                                            <>
                                                {title && <Heading level="4">{title}</Heading>}
                                            </>
                                        }
                                    >
                                        <div style={{ ...(title && { marginTop: 30 }) }}>
                                            {children}
                                        </div>
                                    </ToggleWrapper>
                                ) : (
                                    <>
                                        {title && <Heading level="4">{title}</Heading>}

                                        <div style={{ ...(title && { marginTop: 30 }) }}>
                                            {children}
                                        </div>
                                    </>
                                )
                            }
                        </div>
                    </div>
                </Container>
            </div>
        );

        return (
            <FadeTransition loading={this.state.loading}>
                <AppLayout
                    userName={this.store.profile.getUserNameField('navbar')}
                    companyCode={this.store.app.companyCode}
                >
                    {Object.keys(this.store.policy.policy).length !== 0 && (
                        <Wrapper>
                            <MainTopWrapper
                                profile={this.store.profile}>
                                <Container fluid>
                                    {window.location.pathname.includes('/supervisor/sales') ? (
                                        <div
                                            className={styles.PolicyHeaderBackButton}
                                            onClick={() => window.location.href = '/supervisor/sales'}>
                                            Полисы моих сотрудников
                                        </div>
                                    ) : (
                                        <div
                                            className={styles.PolicyHeaderBackButton}
                                            onClick={() => window.location.href = '/sales'}>
                                            Мои полисы
                                        </div>
                                    )}
                                    <Heading
                                        level="2"
                                        color="#FFFFFF">
                                        <span style={{ marginRight: 8 }}>{this.store.policy.policy.productName}</span>
                                        <Badge style={{
                                            marginLeft: 0,
                                            background: this.headingBadgeData().color,
                                        }}>{this.headingBadgeData().text}</Badge>
                                    </Heading>

                                    <div className={styles.SideWrapper} style={{ padding: '50px 0 65px' }}>
                                        <div className={styles.SideWrapperInner}>
                                            <div className={styles.TitledContentWrapper}>
                                                <TitledContent
                                                    title={'Номер полиса'}
                                                    content={this.store.policy.policy.policyNumber}
                                                    color={'#FFFFFF'}/>
                                                <TitledContent
                                                    title={'Срок действия'}
                                                    content={policyFullDate}
                                                    color={'#FFFFFF'}/>
                                            </div>
                                        </div>
                                    </div>
                                </Container>
                            </MainTopWrapper>
                            <div>
                                <ToggleTabsWrapper>
                                    <Container
                                        fluid
                                        position={null}>
                                        <DetailsContentWrapper padding="0">
                                            <ToggleTabs>
                                                {this.store.policy.policy.tabs && this.store.policy.policy.tabs.map((item, index) => (
                                                    <ToggleTabsItem
                                                        key={index}
                                                        active={this.state.activeTab === index}
                                                        onClick={() => this.setState({
                                                            ...this.state,
                                                            activeTab: index,
                                                        })}>{item.title}</ToggleTabsItem>
                                                ))}
                                            </ToggleTabs>
                                        </DetailsContentWrapper>
                                    </Container>
                                </ToggleTabsWrapper>

                                {this.store.policy.policy.tabs && this.store.policy.policy.tabs.map((tab, tabIndex) => (
                                    this.state.activeTab === tabIndex && (
                                        <div
                                            key={tabIndex}
                                            style={{ padding: '0 0 50px' }}>
                                            {tab.items.map((item, itemIndex) => (
                                                <React.Fragment key={itemIndex}>
                                                    {(item.type === 'tabHeader' && (tab.title === 'График платежей' ? (
                                                        <DetailsContentWrapper
                                                            padding="55px 0 75px"
                                                            backgroundColor="#F8F8FA"
                                                        >
                                                            <div className={styles.TitledContentWrapper}>
                                                                {item.values.map((value, valueIndex) => (
                                                                    <TitledContent
                                                                        key={valueIndex}
                                                                        title={value.title}
                                                                        content={value.subTitle}/>
                                                                ))}
                                                            </div>
                                                        </DetailsContentWrapper>)
                                                        : (
                                                            <DetailsContentWrapper
                                                                padding="55px 0 75px"
                                                                backgroundColor="#F8F8FA"
                                                            >
                                                                <div className={styles.TitledContentWrapper}>
                                                                    {item.values.map((value, valueIndex) => (
                                                                        <TitledContent
                                                                            key={valueIndex}
                                                                            title={value.title}
                                                                            content={value.subTitle}/>
                                                                    ))}
                                                                </div>
                                                            </DetailsContentWrapper>)))}

                                                    {item.type === 'list' && (
                                                        <DetailsContentWrapper
                                                            isToggle={item.closed}
                                                            title={item.title}
                                                        >
                                                            <BaseList className={styles.InfoList}>
                                                                {item.values.map((value, valueIndex) => (
                                                                    <BaseListItem
                                                                        key={valueIndex}
                                                                        title={value.title}>
                                                                        {value.values.map((nestedValue, nestedValueIndex) => (
                                                                            <BaseListText key={nestedValueIndex}
                                                                                text={nestedValue.value}
                                                                                subText={nestedValue.subValue}/>
                                                                        ))}
                                                                    </BaseListItem>
                                                                ))}
                                                            </BaseList>

                                                        </DetailsContentWrapper>
                                                    )}

                                                    {item.type === 'clinic_list' && (
                                                        <DetailsContentWrapper
                                                            isToggle={item.closed}
                                                            title={item.title}
                                                        >
                                                            <ClinicList className={styles.InfoList}>
                                                                {item.values.$mobx.values.map((value, valueIndex) => (
                                                                    <ClinicListItem
                                                                        key={valueIndex}
                                                                        title={value.title}>
                                                                        {value.values.$mobx.values.map((nestedValue, nestedValueIndex) => (
                                                                            <ClinicItem key={nestedValueIndex}
                                                                                text={nestedValue}/>
                                                                        ))}
                                                                    </ClinicListItem>
                                                                ))}
                                                            </ClinicList>

                                                        </DetailsContentWrapper>
                                                    )}

                                                    {item.type === 'content' && (
                                                        <DetailsContentWrapper title={item.title}>
                                                            {item.values.map((value, valueIndex) => (
                                                                <TextContent key={valueIndex}
                                                                    dangerouslySetInnerHTML={{ __html: value.value }}/>
                                                            ))}
                                                        </DetailsContentWrapper>
                                                    )}

                                                    {item.type === 'graphAdditionalIncome' && (
                                                        <DetailsContentWrapper title={item.title}>
                                                            {!this.state.graphAdditionalIncomeData && this.fetchGraphAdditionalIncomeData(item.index)}
                                                            <>
                                                                {this.state.graphAdditionalIncomeData
                                                                    && this.state.graphAdditionalIncomeData.MARKERS
                                                                    && Object.entries(this.state.graphAdditionalIncomeData.MARKERS.Items).length > 0
                                                                    && <Chart
                                                                        data={this.state.graphAdditionalIncomeData}
                                                                        index={item.index}
                                                                        company={item.company}
                                                                        analytics={item.analytics}
                                                                    />
                                                                }
                                                                {(strategyWithReportPolicy.indexOf(item.index) !== -1) && (
                                                                    <ChartReportPolicy
                                                                        policyId={this.props.match.params}
                                                                        email={this.store.profile.user.email}
                                                                    />
                                                                )}
                                                            </>
                                                        </DetailsContentWrapper>
                                                    )}

                                                    {item.type === 'taxDeductionWidget' && (
                                                        <DetailsContentWrapper>
                                                            <TaxDeductionWidget
                                                                store={this.store}
                                                                minDate={this.store.policy.policy.contractBeginDate}
                                                                maxDate={this.store.policy.policy.contractEndDate}
                                                                policyId={this.props.match.params.policyId}
                                                            />
                                                        </DetailsContentWrapper>
                                                    )}

                                                    {(item.type === 'redemption_sums_table' || item.type_table === 'redemption_sums_table') && (
                                                        <DetailsContentWrapper
                                                            title={item.title}
                                                            isToggle={item.closed}
                                                        >
                                                            <RedemptionSumsTable rows={item.rows}/>
                                                        </DetailsContentWrapper>
                                                    )}

                                                    {(item.type === 'basic_asset_table' || item.type_table === 'basic_asset_table') && (
                                                        <DetailsContentWrapper
                                                            title={item.title}
                                                            isToggle={item.closed}
                                                        >
                                                            <BasicAssetTable rows={item.rows}/>
                                                        </DetailsContentWrapper>
                                                    )}

                                                    {(item.type === 'equal_columns_table' || item.type_table === 'equal_columns_table') && (
                                                        <DetailsContentWrapper
                                                            title={item.title}
                                                            isToggle={item.closed}
                                                        >
                                                            <EqualColumnsTable rows={item.rows}/>
                                                        </DetailsContentWrapper>
                                                    )}

                                                    {(item.type === 'payment_schedule_table' || item.type_table === 'payment_schedule_table' || item.table_type === 'payment_schedule_table') && (
                                                        <DetailsContentWrapper
                                                            title={item.title}
                                                            isToggle={item.closed}
                                                        >
                                                            <PaymentScheduleTable
                                                                years={item.years}
                                                                policyId={this.props.match.params.policyId}
                                                                company={this.store.policy.policy.companyCode}
                                                            >
                                                                <Button
                                                                    buttonType="text"
                                                                    variant="primary"
                                                                >
                                                                    {'Оплатить'}
                                                                </Button>
                                                            </PaymentScheduleTable>
                                                        </DetailsContentWrapper>
                                                    )}

                                                    {(item.type === 'investment_table_2' || item.type_table === 'investment_table_2' || item.table_type === 'investment_table_2' || item.type === 'investment_table' || item.type_table === 'investment_table' || item.table_type === 'investment_table') && (
                                                        <DetailsContentWrapper
                                                            title={item.title}
                                                            isToggle={item.closed}
                                                        >
                                                            {item.rows[2] && <InvestmentTable rows={item.rows}/>}
                                                        </DetailsContentWrapper>
                                                    )}

                                                    {(item.type === 'additional_profit_table' || item.type_table === 'additional_profit_table' || item.table_type === 'additional_profit_table') && (
                                                        <DetailsContentWrapper
                                                            title={item.title}
                                                            isToggle={item.closed}
                                                        >
                                                            <AdditionalProfitTable rows={item.rows}/>
                                                        </DetailsContentWrapper>
                                                    )}
                                                </React.Fragment>
                                            ))}
                                        </div>
                                    )
                                ))}

                                {this.state.clampList.length !== 0 && (
                                    <div style={{ backgroundColor: '#F8F8FA' }}>
                                        <Container fluid>
                                            <div className={styles.SideWrapper}>
                                                <div className={styles.SideWrapperInner}>
                                                    <div style={{ marginTop: 85, marginBottom: 75 }}>
                                                        <Heading level="3">{'История изменений'}</Heading>

                                                        <BaseTable className={styles.ClampTable}>
                                                            <tbody>
                                                                {this.state.clampList.map((item, itemIndex) => (
                                                                    <tr
                                                                        key={itemIndex}
                                                                        data-status={item.status.code}
                                                                    >
                                                                        <td data-col="date">{moment(item.dateAndTime).format('DD.MM.YYYY')}</td>

                                                                        <td data-col="name">
                                                                            <span>{item.name}</span>
                                                                            <span data-rel="subTitle"></span>
                                                                        </td>

                                                                        <td data-col="status">{item.status.name}</td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </BaseTable>
                                                    </div>
                                                </div>
                                            </div>
                                        </Container>
                                    </div>
                                )}
                            </div>
                        </Wrapper>
                    )}
                </AppLayout>
            </FadeTransition>
        );
    }
}

export default inject('store')(observer(PolicyPage));
