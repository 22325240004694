import React, { useEffect, useState } from 'react';
import axios from 'axios';
import moment from 'moment';
import Button from 'components/Button';
import DateInputField from 'components/DateInputField';
import styles from './styles.module.scss';

const TaxDeductionWidget = ({
    store,
    policyId,
    minDate,
    maxDate,
}) => {
    const [loading, setLoading] = useState(false);
    const [submitDisabled, setSubmitDisabled] = useState(false);
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState(null);

    useEffect(() => {
        setSubmitDisabled(loading || !startDate || !endDate);
    }, [loading, startDate, endDate]);

    const handleSubmit = (e) => {
        e.preventDefault();

        const url = `/api?method=Insurance&action=getSocialTaxDeduction&policyId=${policyId}&yearFrom=${moment(startDate).format('YYYY')}&yearTo=${moment(endDate).format('YYYY')}`;

        setLoading(true);

        axios.get(url)
            .then((response) => {
                const { data: { errorResult = null } = {} } = response;

                if (errorResult) {
                    const { message } = errorResult;

                    throw new Error(message);
                }

                window.open(url, '_blank');

                setLoading(false);
            })
            .catch((error) => {
                store.app.setModalData({
                    title: 'Ошибка',
                    body: error.message,
                });

                setLoading(false);
            });
    };

    return (
        <div className={styles.Wrapper}>
            <DateInputField
                mask={[/[0-9]/, /\d/, /\d/, /\d/]}
                selectsStart
                showYearPicker
                startDate={startDate}
                minDate={moment(minDate).subtract(1, 'year').toDate()}
                maxDate={endDate || moment(maxDate).toDate()}
                label="Период, от"
                size="lg"
                value={startDate}
                format="yyyy"
                placeholder="гггг"
                onChange={(value) => setStartDate(value)} />

            <DateInputField
                mask={[/[0-9]/, /\d/, /\d/, /\d/]}
                selectsEnd
                showYearPicker={true}
                endDate={endDate}
                minDate={startDate || moment(minDate).toDate()}
                maxDate={moment(maxDate).toDate()}
                size="lg"
                value={endDate}
                format="yyyy"
                placeholder="гггг"
                onChange={(value) => setEndDate(value)} />

            <Button
                size="lg"
                type="submit"
                variant="primary"
                loading={loading}
                disabled={submitDisabled}
                onClick={handleSubmit}
            >{ 'Сформировать справку' }</Button>
        </div>
    );
};

export default TaxDeductionWidget;
