import React from 'react';
import Container from 'components/Container';
import styles from './styles.module.scss';

const Footer = () => (
    <footer className={styles.Footer}>
        <Container fluid>
            <address
                itemScope
                itemType="http://schema.org/Organization"
                className={styles.FooterAddress}
            >
                <a
                    className={styles.FooterAddressEmail}
                    href="mailto:inslife@in-life.ru"
                >
                    inslife@in-life.ru
                </a>

                <a className={styles.FooterAddressSecondPhone} href="tel:88002349202">
                    8 800 234-92-02
                </a>

                {/* <div className={styles.FooterAddressLocation}>
                    125284, Россия, г. Москва,
                    <br/>
                    пр-кт Ленинградский, д. 35 стр. 1
                </div> */}
                {/* Скрытый блок с адресом - только для микроразметки */}
                {/* <div
                    itemProp="address"
                    itemScope
                    itemType="http://schema.org/PostalAddress"
                    className={styles.FooterAddressLocationHidden}
                >
                    <span itemProp="postalCode">125284</span>
                    <span itemProp="addressLocality">Россия, г. Москва</span>
                    <span itemProp="streetAddress">пр-кт Ленинградский, д. 35 стр. 1</span>
                </div> */}

                {/* <div className={styles.FooterSocial}>
                     <a
                        href="https://fb.com/uralsibins"
                        target="_blank"
                        rel="noopener noreferrer"
                        className={styles.FooterSocialFB}
                     >
                     fb
                     </a>
                    <a
                        href="https://vk.com/uralsibins"
                        target="_blank"
                        rel="noopener noreferrer"
                        className={styles.FooterSocialVK}
                    >
                    vk
                    </a>
                </div> */}
            </address>

            <div className={styles.FooterCopyright}>
                <div className={styles.FooterCopyrightText}>
                    {'© АО «Инлайф страхование жизни». Лицензии на осуществление страхования '}
                    <a className={styles.FooterCopyrightText}
                        href="https://in-life.ru/kompaniya/raskrytie-informatsii">
                        {'CЖ №3987 и СЛ №3987'}</a>
                    {' выданы Банком России без ограничения срока действия.'}
                </div>
            </div>
        </Container>
    </footer>
);

export default Footer;
