import AppModule from './modules/AppModule';
import ProfileModule from './modules/ProfileModule';
import PolicyModule from './modules/PolicyModule';
import LoadingModule from './modules/LoadingModule';
import NewsModule from './modules/NewsModule';

const store = {};

export default function initializeStore(initialData = {
    app: {},
    login: {},
    profile: {},
    policy: {},
    loading: {},
    news: {},
}) {
    store.app = new AppModule();
    store.profile = new ProfileModule(initialData.profile, store.app);
    store.policy = new PolicyModule(initialData.policy, store.app);
    store.loading = new LoadingModule(initialData.loading);
    store.news = new NewsModule(initialData.news);

    return store;
}
