import React from 'react';
import { inject, observer } from 'mobx-react';
import AppLayout from 'layouts/AppLayout';
import Wrapper from 'components/Wrapper';
import ContentWrapper from 'components/ContentWrapper';
import Button from 'components/Button';
import cn from 'classnames';
import UploadProfileImageModal from 'components/UploadProfileImageModal';
import LevelFunctionalityModal from 'components/LevelFunctionalityModal';
import moment from 'moment';
import MainTopWrapper from '../../components/MainTopWrapper';
import styles from './styles.module.scss';
import UserLevelTooltip from '../../components/UserLevelTooltip';
import planetSimpleSilver from '../../assets/icons/profile/Silver/planetSimpleSilver.png';
import planetSimpleGold from '../../assets/icons/profile/Gold/planetSimpleGold.png';
import planetSimplePlatinum from '../../assets/icons/profile/Platinum/planetSimplePlatinum.png';
import infoSign from '../../assets/icons/profile/info-sign.svg';
import progressBarSilver from '../../assets/icons/profile/Silver/progressBarSilver.svg';
import progressBarGold from '../../assets/icons/profile/Gold/progressBarGold.svg';
import progressBarPlatinum from '../../assets/icons/profile/Platinum/progressBarPlatinum.svg';
import { getLevelAdvantages } from '../../helpers/advantages.js';
import { UPLOAD_PROFILE_IMAGE } from '../../api';

const getSimplePlanet = (level) => {
    switch (level) {
    case 'Silver':
        return planetSimpleSilver;
    case 'Gold':
        return planetSimpleGold;
    case 'Platinum':
        return planetSimplePlatinum;
    default:
        return '';
    }
};

const getProgressBar = (level) => {
    switch (level) {
    case 'Silver':
        return progressBarSilver;
    case 'Gold':
        return progressBarGold;
    case 'Platinum':
        return progressBarPlatinum;
    default:
        return '';
    }
};

const getNextPeriodLevel = (level) => {
    switch (level) {
    case 'Silver':
        return 'Gold';
    case 'Gold':
        return 'Platinum';
    case 'Platinum':
        return 'Platinum';
    default:
        return '';
    }
};

class ProfilePage extends React.Component {
    constructor(props) {
        super(props);
        this.store = props.store;
        this.state = {
            title: 'Личный кабинет продавца',
            isShowUploadProfileImageModal: false,
            selectedImage: null,
            isShowLevelFunctionalityModal: false,
            loadingUpdateProfileImage: false,
        };
    }

    onChooseImage = (event) => {
        const selectedImage = event.target.files[0];
        const filesError = [];

        const maxFileSize = 2;
        const allowedExtensions = /(\.jpeg|\.jpg|\.gif|\.png)$/i;
        const { size: fileSize, name: fileName } = selectedImage;
        const fileSizeMB = (fileSize / (1024 * 1024)).toFixed(2);
        const checkAllowedExtensions = allowedExtensions.exec(fileName);

        if (!checkAllowedExtensions) {
            filesError.push(`Недопустимое расширение у файла ${fileName}`);
        } else if (fileSizeMB > maxFileSize) {
            filesError.push(`Размер файла ${fileName} превышает допустимые ${maxFileSize}мб.`);
        } else {
            this.setState({ selectedImage });
        }

        if (filesError.length !== 0) {
            this.store.app.setModalData({
                title: 'Ошибка',
                body: filesError.join('\n'),
            });
        }
    };

    onUploadImage = () => {
        const { login } = this.store.profile.user;
        const file = this.state.selectedImage;
        this.setState({ loadingUpdateProfileImage: true });
        UPLOAD_PROFILE_IMAGE(login, file)
            .then(() => {
                this.store.profile.getClientInfo()
                    .then(() => this.setState({ loadingUpdateProfileImage: false },
                        () => this.setState({ isShowUploadProfileImageModal: false })))
                    .catch(() => {
                        this.store.app.setModalData({
                            title: 'Ошибка',
                            body: 'Ошибка получения данных профиля',
                        });
                    });
            })
            .catch((error) => {
                this.store.app.setModalData({
                    title: 'Ошибка',
                    body: error.message,
                });
            });
    };

    componentDidMount() {
        document.title = this.state.title;
    }

    render() {
        const currentPeriodLevel = this.store.profile.user.level && this.store.profile.user.level.currentPeriod.level;
        const nextPeriodLevel = this.store.profile.user.level && this.store.profile.user.level.nextPeriod.level;
        const { profileImageUrl } = this.store.profile;
        return (
            <AppLayout
                userName={this.store.profile.getUserNameField('navbar')}
                companyCode={this.store.app.companyCode}
            >
                <Wrapper>
                    <MainTopWrapper
                        profile={this.store.profile}
                    />
                    {this.state.isShowUploadProfileImageModal && (
                        <UploadProfileImageModal
                            handleClose={() => this.setState({ isShowUploadProfileImageModal: false })}
                            selectedImage={this.state.selectedImage}
                            onChooseImage={this.onChooseImage}
                            onUploadImage={this.onUploadImage}
                            loading={this.state.loadingUpdateProfileImage}
                        />
                    )}
                    {this.state.isShowLevelFunctionalityModal && (
                        <LevelFunctionalityModal
                            handleClose={() => this.setState({ isShowLevelFunctionalityModal: false })}
                        />
                    )}
                    <ContentWrapper>
                        <div className={styles.ProfilePageTitle}>
                            {'Мой профиль'}
                        </div>
                        <div className={styles.ProfilePageCard}>
                            <div className={styles.ProfilePageCardHeader}>
                                <div className={styles.ProfilePhotoWrapper}>
                                    <img
                                        className={styles.ProfilePhoto}
                                        src={profileImageUrl || 'images/profile/user.jpg'}
                                        alt={'Фото профиля'}
                                    />
                                    <Button
                                        style={{ color: '#0D63D6' }}
                                        onClick={() => this.setState({ isShowUploadProfileImageModal: true })}
                                    >
                                        {'Загрузить фото'}
                                    </Button>
                                </div>
                                <div className={styles.ProfileInfoWrapper}>
                                    <div className={styles.ProfileInfoName}>
                                        {`${this.store.profile.getUserNameField('name')} ${this.store.profile.getUserNameField('surname')}`}
                                    </div>
                                    <div className={styles.ProfileInfoDetails}>
                                        <div className={cn(styles.ProfileInfoItem, styles.ProfileInfoItem__ImageWrapper)}>
                                            <div className={cn(styles.ProfileInfoItem__Image,
                                                styles[`ProfileInfoItem__Image_${currentPeriodLevel}`])}></div>
                                            <div className={styles.ProfileInfoItem__Title}>
                                                {`Уровень: ${this.store.profile.getUserLevelPlanet('levelPlanet')}`}
                                            </div>
                                        </div>
                                        <div
                                            className={cn(styles.ProfileInfoItem, styles.ProfileInfoItem__Balance)}>
                                            {`Уралкоинов: ${this.store.profile.user.balance && this.store.profile.user.balance.total.toLocaleString()}`}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={styles.ProfilePageCardLevel}>
                                <img
                                    src={getSimplePlanet(currentPeriodLevel)}
                                    alt="Планета уровня"
                                    className={styles.ProfilePageCardLevel__Planet}/>
                                <div className={styles.ProfilePageCardLevel__LevelInfo}>
                                    <div className={cn(styles.ProfilePageCardLevel__LevelName,
                                        styles[`ProfilePageCardLevel__LevelName_${currentPeriodLevel}`])}>
                                        <div>
                                            {this.store.profile.getUserLevelPlanet('levelPlanet')}
                                        </div>
                                        <Button
                                            className={styles.ProfilePageCardLevel__Functionality}
                                            onClick={() => this.setState({ isShowLevelFunctionalityModal: true })}
                                        >
                                            <img
                                                src={infoSign}
                                                alt={'Функционал уровней'}/>
                                        </Button>
                                    </div>
                                    <div className={styles.ProfilePageCardLevel__DateActiveUntil}>
                                        {`Бонусы действуют до ${this.store.profile.user.level && moment(this.store.profile.user.level.currentPeriod.validTo).format('DD.MM.YY')}`}
                                    </div>
                                </div>
                            </div>
                            <div className={styles.ProfilePageCardCapabilities}>
                                <div className={styles.CapabilitiesTitle}>
                                    {'Вам доступны'}
                                </div>
                                <div className={styles.CapabilitiesItemWrapper}>
                                    {getLevelAdvantages(currentPeriodLevel).map((advantageItem, index) => <div
                                        key={index}
                                        className={styles.CapabilitiesItem}>
                                        {advantageItem}
                                    </div>)
                                    }
                                </div>
                            </div>
                            <div className={styles.ProfilePageCardNextQuarterLevel}>
                                <div className={styles.ProfilePageCardNextQuarterLevel__Title}>
                                    {'Выполнение условий уровней на следующий квартал'}
                                </div>
                                <div className={styles.ProfilePageCardNextQuarterLevel__Dates}>
                                    {`с ${this.store.profile.user.level && moment(this.store.profile.user.level.nextPeriod.validFrom).format('DD.MM.YY')}г по 
                                        ${this.store.profile.user.level && moment(this.store.profile.user.level.nextPeriod.validTo).format('DD.MM.YY')}г`}
                                </div>
                            </div>
                            <div className={styles.ProfilePageCardLevelInfo}>
                                <div className={styles.ProfilePageCardLevelInfo__ProgressBar}>
                                    <UserLevelTooltip
                                        period={'nextPeriod'}
                                        level={getNextPeriodLevel(nextPeriodLevel)}
                                        requirements={this.store.profile.user.level && this.store.profile.user.level.nextPeriod.requirementsForPromotionToNextLevel}
                                    >
                                        <img
                                            src={getProgressBar(nextPeriodLevel)}
                                            alt="Прогресс-бар"
                                            className={styles.ProfilePageCardLevelInfo__ProgressBar__Image}/>
                                    </UserLevelTooltip>
                                </div>
                            </div>
                        </div>
                    </ContentWrapper>
                </Wrapper>
            </AppLayout>
        );
    }
}

export default inject('store')(observer(ProfilePage));
