import React from 'react';
import cn from 'classnames';
import Icon from './HeartIcon';
import styles from './Preloader.module.scss';

class Preloader extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false,
            isAppeared: false,
        };
    }

    componentDidMount() {
        this.setState({ isLoaded: true }, () => {
            setTimeout(() => {
                this.setState({ isAppeared: true });
            }, 700);
        });
    }

    render() {
        const {
            isLoaded,
            isAppeared,
        } = this.state;
        return (
            <div className={cn(styles.wrapper)}>
                <Icon className={cn(styles.logo, isLoaded && !isAppeared && styles.appear, isAppeared && styles.pulse)} />
            </div>
        );
    }
}

export default Preloader;
