import React, { useState } from 'react';
import PolicyScheduleTabs from '../../../PolicyScheduleTabs';
import styles from './styles.module.scss';
import PolicyBaseTable from '../PolicyBaseTable';

const PaymentScheduleTable = ({
    years,
    children,
    policyId,
    company,
}) => {
    const [activeYear, setActiveYear] = useState(() => {
        let key = 0;
        if (company === 'SK') {
            years.forEach((year) => {
                Object.keys(year).forEach((yearKey, yearKeyIndex) => {
                    if (year[yearKey].rows[1].row_type === 'table_highlighted_body') {
                        key = yearKeyIndex;
                    }
                });
            });
        } else if (company === 'Life') {
            const currentYear = new Date().getFullYear();
            let defaultYear = 0;
            let minYear = 0;
            let maxYear = 0;
            years.forEach((year) => {
                Object.keys(year).forEach((yearKey, yearKeyIndex) => {
                    if (yearKeyIndex === 0) {
                        minYear = yearKey;
                    }
                    if (yearKey > maxYear) {
                        maxYear = yearKey;
                    }
                    if (Number(yearKey) === currentYear) {
                        defaultYear = yearKeyIndex;
                    }
                });
            });
            if (defaultYear) {
                key = defaultYear;
            } else if (currentYear < minYear) {
                key = minYear;
            } else if (currentYear > maxYear) {
                key = maxYear;
            }
        }
        return key;
    });
    return (
        <div>
            <div className={styles.PaymentScheduleYears}>
                {years.map((year, yearIndex) => Object.keys(year).map((yearKey, yearKeyIndex) => (
                    <button
                        key={`${yearIndex}-${yearKeyIndex}`}
                        className={styles.PaymentScheduleTableButton}
                        onClick={() => setActiveYear(yearKeyIndex)}
                        data-active={yearKeyIndex === activeYear}>
                        { yearKey }
                    </button>
                )))}
            </div>

            {years.map((year) => Object.keys(year).map((yearKey, yearKeyIndex) => {
                const { rows } = year[yearKey];
                if (yearKeyIndex === activeYear) {
                    if (company === 'SK') {
                        return (
                            <PolicyBaseTable
                                key={yearKeyIndex}
                                rows={rows}
                                className={styles.PaymentScheduleTable}
                                policyId={policyId}
                            >
                                { children }
                            </PolicyBaseTable>
                        );
                    } if (company === 'Life') {
                        return (
                            <PolicyScheduleTabs
                                key={yearKeyIndex}
                                rows={year[yearKey].rows}
                            />
                        );
                    }
                }
                return null;
            }))}
        </div>
    );
};

export default PaymentScheduleTable;
