import React from 'react';
import Heading from 'components/Heading';
import CartListOrderTextField from 'components/StyledComponents/CartListOrderTextField';
import Button from 'components/Button';
import InputMask from 'react-input-mask';
import styles from './styles.module.scss';
import CartItem from '../CartItem';
import { ADD_BASKET, ORDER_PLACE, BASKET_DELETE } from '../../api';
import Modal from '../Modal';

class CartList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            totalPrice: 0,
            productsNumber: 0,
            deliveryDate: '',
            pointsAvailable: this.props.pointsAvailable,
            basketList: this.props.basketList,
            basket: this.props.basket,
            isModalVisible: false,
            userPhone: '',
            address: '',
            title: '',
            buttonDisabled: true,
            validationAddress: false,
            validationPhone: false,
        };
        this.closeModal = this.closeModal.bind(this);
        this.handleChangePhone = this.handleChangePhone.bind(this);
        this.handleChangeAddress = this.handleChangeAddress.bind(this);
    }

    validateForm() {
        let isDisabled = this.state.validationPhone | this.state.validationAddress | this.state.basket.totalPrice > this.state.pointsAvailable;

        if (!(new RegExp('^((8|\\+7)[\\- ]?)?(\\(?\\d{3}\\)?[\\- ]?)?[\\d\\- ]{7,10}$').test(this.state.userPhone))
             || this.state.address === '') {
            isDisabled = true;
        }

        this.setState({
            buttonDisabled: isDisabled,
        });
    }

    handleChangePhone(e) {
        let errorPhone = true;

        if (e.target.value && new RegExp('^((8|\\+7)[\\- ]?)?(\\(?\\d{3}\\)?[\\- ]?)?[\\d\\- ]{7,10}$').test(e.target.value)) {
            errorPhone = false;
        }

        this.setState({
            userPhone: e.target.value,
            validationPhone: errorPhone,
        });

        this.validateForm();
    }

    handleChangeAddress(e) {
        let errorAddress = true;

        if (e.target.value !== '') {
            errorAddress = false;
        }

        this.setState({
            address: e.target.value,
            validationAddress: errorAddress,
        });

        this.validateForm();
    }

    addItemToBasket(item, itemNumber) {
        ADD_BASKET(item.id, item.title, item.price, itemNumber, item.quantity, item.previewImage.id)
            .then((data) => {
                if (data.success === 'ok') {
                    console.log('Товар добавлен в корзину');
                    window.location.reload();
                } else { console.log('Ошибка добавления в корзину. Обратитесь к администратору'); }
            }).catch((error) => {
                console.log(error);
            });
    }

    deleteItemFromBasket(item) {
        BASKET_DELETE(item.id)
            .then((data) => {
                if (data.success === 'ok') {
                    console.log('Товар удалён');
                    window.location.reload();
                } else {
                    console.log('Ошибка удаления товара.');
                }
            }).catch((error) => {
                console.log(error);
            });
    }

    orderBasket() {
        ORDER_PLACE(this.state.address, this.state.userPhone)
            .then((data) => {
                if (data.success === 'ok') {
                    this.setState({
                        isModalVisible: true,
                        title: 'Ваш заказ оформлен',
                    });
                } else {
                    this.setState({
                        isModalVisible: true,
                        title: 'Ошибка оформления заказа. Обратитесь к администратору',
                    });
                }
            }).catch((error) => {
                console.log(error);
                this.setState({
                    isModalVisible: true,
                    title: 'Ошибка оформления заказа. Обратитесь к администратору',
                });
            });
    }

    closeModal() {
        this.setState({
            isModalVisible: false,
        });
        if (this.state.title === 'Ваш заказ оформлен') {
            window.location.replace('/orders');
        }
    }

    render() {
        const isNotEnoughPoints = this.state.basket.totalPrice > this.state.pointsAvailable;
        const InputPhoneError = this.state.validationPhone;

        return (
            <div className={styles.CartList}>
                <div className={styles.CartListWrapper}>
                    {this.state.isModalVisible && <Modal
                        title={this.state.title }
                        handleClose={this.closeModal}/>
                    }
                    <div className={styles.CartListTitle}>
                        <Heading className={styles.CartListHeading} level="4">{ 'Корзина' }</Heading>
                    </div>
                    <div className={styles.CartListContent}>
                        {this.state.basketList.length ? this.state.basketList.map((item, index) => (
                            <CartItem
                                key={index}
                                data={item}
                                deleteItemFromBasket = {this.deleteItemFromBasket}
                                addItemToBasket={this.addItemToBasket}/>
                        )) : ''}
                    </div>
                </div>
                <div className={styles.CartListTotal}>
                    <div className={styles.CartListTotalHeader}>
                        <div className={styles.CartListTotalTitle}>Итого</div>
                        <div className={styles.CartListTotalPrice}>{ this.state.basket.totalPrice.toLocaleString() } У</div>
                    </div>
                    <div className={styles.CartListProducts}>
                        <div className={styles.CartListProductsTitle}>Товары</div>
                        <div className={styles.CartListProductsNumber}>{ this.state.basket.productsNumber } шт</div>
                    </div>
                    <div className={styles.CartListDelivery}>
                        <div className={styles.CartListDeliveryTitle}>Доставка</div>
                        <div className={styles.CartListDeliveryDays}>28 дней</div>
                        <div className={styles.CartListDeliveryDate}>{ this.state.deliveryDate }</div>
                    </div>
                    <div className={styles.CartListPoints}>
                        <div className={styles.CartListPointsTitle}>Баллов доступно к списанию</div>
                        <div className={styles.CartListPointsNumber}>{ this.state.pointsAvailable.toLocaleString() } У</div>
                    </div>
                    {isNotEnoughPoints
                        && <div className={styles.CartListNotEnoughPoints}>
                            <div className={styles.CartListNotEnoughPointsTitle}>Недостаточно баллов для оформления заказа</div>
                        </div>}

                    <div className={styles.CartListInputText}>
                        <div className={styles.CartListAddressTitle}>Адрес доставки (офис Уралсиб) </div>
                        <div className={styles.CartListAddressInput}>
                            <CartListOrderTextField id="formAddress" type="text" placeholder="Адрес доставки" name="address"
                                error={this.state.validationAddress}
                                className={styles.InputType} onChange={this.handleChangeAddress}/><br/>
                        </div>
                    </div>
                    <div className={styles.CartListInputText}>
                        <div className={styles.CartListPhoneTitle}>Номер телефона для курьера</div>
                        <div className={`${InputPhoneError ? styles.CartListPhoneInputError : styles.CartListPhoneInput}`}>
                            <InputMask className={styles.InputType} placeholder="Телефон" name="userPhone"
                                onChange={this.handleChangePhone} mask='+7 999-999-99-99' maskChar=" "/>
                        </div>
                    </div>
                    <div className={styles.CartListInputText}>
                        Я даю <a href='/upload/soglasie_seller.pdf' target='_blank'>согласие</a> АО «Инлайф страхование жизни» на обработку моих персональных данных для целей доставки
                    </div>
                    <div className={styles.CartListOrderButton}>
                        <Button
                            variant="primary"
                            size="lg"
                            disabled={this.state.buttonDisabled}
                            onClick={() => this.orderBasket()}
                            style={{ width: 378 }}
                        >
                            Заказать
                        </Button>
                    </div>
                </div>
            </div>
        );
    }
}

export default CartList;
